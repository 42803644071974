<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=1 />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">企业认证<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb resetpwd">
                  <el-form :model="form" :label-position="labelPosition" v-loading="loading" :rules="rules"
                    label-width="100px" class="elform" ref="elForm">
                    <el-form-item label="营业执照" prop="businesslicenseimg">
                      <el-upload style="float:left;" :multiple="false" class="upload-demo" :headers="myHeaders"
                        list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                        :action="uploadUrl" name="file" accept="image/png,image/gif,image/jpg,image/jpeg"
                        :file-list="fileList" :on-success="busSuccess" :on-error="busError" :on-change="busChange">
                        <i class="el-icon-plus"></i>
                      </el-upload>
                    </el-form-item>
                    <el-form-item label="企业类目" prop="entregcategorytxt">
                      <el-select placeholder="请企业类目" @change="onentregcategorychange" v-model="form.entregcategorytxt">
                        <el-option value="" label="">请选择</el-option>
                        <el-option v-for="item in entregcategorydata" :key="item.id" :label="item.data_title"
                          :value="item.data_title">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item class="formL" label="名称" prop="name">
                      <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item class="formL" label="简称" prop="shortname" v-if="false">
                      <el-input v-model="form.shortname"></el-input>
                    </el-form-item>
                    <el-form-item class="formL small" label="统一社会信用代码" prop="creditcode">
                      <el-input v-model="form.creditcode"></el-input>
                    </el-form-item>
                    <el-form-item label="公司类型" prop="entregtypetxt">
                      <el-select placeholder="请选择行业" @change="onentregtypechange" v-model="form.entregtypetxt">
                        <el-option value="" label="">请选择</el-option>
                        <el-option v-for="item in entregtypedata" :key="item.id" :label="item.data_title" :value="item.data_title">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item class="formL" v-if="!isbranch" label="法人" prop="legalperson">
                      <el-input v-model="form.legalperson"></el-input>
                    </el-form-item>
                    <el-form-item class="formL" v-if="!isbranch" label="注册资本" prop="entregcapital">
                      <el-input v-model="form.entregcapital" style="width:250px"
                        oninput="if(value.length>6)value=value.slice(0,6)" type="number"></el-input>
                      <el-button class="chooseaddress" type="danger">万元</el-button>
                    </el-form-item>
                    <el-form-item label="成立日期" prop="establishdate">
                      <el-col :span="11">
                        <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"
                          v-model="form.establishdate" style="width: 100%;">
                        </el-date-picker>
                      </el-col>
                    </el-form-item>
                    <el-form-item class="small" label="营业期限自" prop="businessstart">
                      <el-col :span="11">
                        <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"
                          v-model="form.businessstart" style="width: 100%;">
                        </el-date-picker>
                      </el-col>
                    </el-form-item>
                    <el-form-item class="formL small" label="营业期限至" prop="businessend">
                      <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                        @change="onbusinessendchange" v-if="!isshowbusinessend" placeholder="选择日期" v-model="businessend"
                        style="width: 164px;margin-right:10px">
                      </el-date-picker>
                      <el-checkbox v-model="isshowbusinessend" border @change="onshowbusinessend">长期</el-checkbox>
                    </el-form-item>
                    <el-form-item class="formL" label="登记机关" prop="entregauth">
                      <el-input v-model="form.entregauth"></el-input>
                    </el-form-item>
                    <el-form-item label="登记状态" prop="entregstatustxt">
                      <el-select placeholder="请选择行业" @change="onentregstatuschange" v-model="form.entregstatustxt">
                        <el-option value="" label="">请选择</el-option>
                        <el-option v-for="item in entregstatusdata" :key="item.id" :label="item.data_title"
                          :value="item.data_title">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="公司所在地"  style="width:600px" class="formaddress small" prop="province">
                       <el-cascader ref="addressCascader" :options="city" v-model="form.province"  @change="oncitycascader" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label="详细地址" class="formaddress" prop="address">
                      <el-input placeholder="请输入详细地址" v-model="form.address" style="width:250px;"></el-input>
                    </el-form-item>
                    <el-form-item class="formL" label="联系电话" prop="companytel">
                      <el-input v-model="form.companytel"></el-input>
                    </el-form-item>
                    <el-form-item label="经营范围" v-if="!isbranch" class="businessscope" prop="businessscope">
                      <el-input type="textarea" v-model="form.businessscope"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button @click="onsubmitForm('elForm')">下一步</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogimgVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" top="25vh" width="40%" :before-close="handleClose">
      <el-row>
        <div class="amap-page-container" style="margin-top:-15px;">
          <div class="address">{{address}}</div>
          <el-amap vid="amapDemo" :center="center" :zoom="zoom" class="amapstyle" :events="events">
            <el-amap-marker v-for="(marker, index) in markers" :position="marker" :key="index"></el-amap-marker>
          </el-amap>
          <div style="text-align:center;margin-top:20px;">
            <el-button type="primary" size="mini" @click="locatefal"><span style="margin:0 30px">确定</span></el-button>
          </div>
        </div>
      </el-row>
    </el-dialog>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import { cityjson } from '@/utils/cityjson.js';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import {
  companygetentregcategory,
  companygetentregtype,
  companygetentregstatus,
  utilsbusinesslicenseocr,
  companyauth,
} from '@/api/user';
import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    return {
      province:[],
      city:cityjson,
      isbranch: false,
      loading: false,
      dialogImageUrl: '',
      businessend: '',
      isshowbusinessend: false,
      dialogVisible: false,
      dialogimgVisible: false,
      labelPosition: 'right',
      firstShow: true,
      show: false,
      height: '600',
      entregtypedata: [],
      entregcategorydata: [],
      entregstatusdata: [],
      fileList: [],
      myHeaders: { Authorization: 'Bearer ' + token },
      uploadUrl: this.$uploadUrlN + '/utils/uploadfile',
      form: {
        name: '',
        shortname: '',
        companytel: '',
        creditcode: '',
        legalperson: '',
        entregcapital: '',
        entregcategorytxt: '',
        entregcategoryid: '',
        entregtypeid: '',
        entregtypetxt: '',
        establishdate: '',
        businessstart: '',
        businessend: '',
        entregauth: '',
        entregstatusid: '',
        entregstatustxt: '',
        address: '',
        provinceid:'',
        cityid:'',
        countyid:'',
        provincename:'',
        cityname:'',
        countyname:'',
        province:[],
        businessscope: '',
        businesslicenseimg: '',
        Adcode: '',
        LnglatAmap: '',
      },
      rules: {
        name: [{ required: true, message: '请输入企业名称' }],
        shortname: [{ required: false, message: '请输入企业简称' }],
        creditcode: [{ required: true, message: '请输入统一社会信用代码' }],
        entregcategorytxt: [{ required: true, message: '请选择企业类目' }],
        entregtypetxt: [{ required: true, message: '请选择公司类型' }],
        legalperson: [{ required: true, message: '请输入公司法人' }],
        entregcapital: [{ required: true, message: '请输入注册资本' }],
        establishdate: [{ required: true, message: '请输入成立日期' }],
        businessstart: [{ required: true, message: '请输入营业期限自' }],
        businessend: [{ required: true, message: '请输入营业期限至' }],
        entregauth: [{ required: true, message: '请输入登记机关' }],
        entregstatustxt: [{ required: true, message: '请选择登记状态' }],
        address: [{ required: true, message: '请输入公司地址' }],
        province: [{type: 'array',  required: true, message: '请选择省市区' }],
        companytel: [{ required: true, message: '请输入联系电话' }],
        businessscope: [{ required: true, message: '请输入经营范围' }],
        businesslicenseimg: [{ required: true, message: '请上传营业执照' }],
      },
      markers: [],
      center: [117.127665, 36.679559],
      zoom: 15,
      // address: '',
      lng: 117.127665,
      lat: 36.679559,
      address: '',
      addForm: {
        longitude: '',
        latitude: '',
      },
      events: {
        click(e) {
          self.markers = [];
          let { lng, lat } = e.lnglat;
          // self.addForm.longitude = lng
          // self.addForm.latitude = lat
          self.center = [lng, lat];
          self.markers.push([lng, lat]);
          // 这里通过高德 SDK 完成。
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all',
          });
          geocoder.getAddress([lng, lat], function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              if (result && result.regeocode) {
                self.form.address = result.regeocode.formattedAddress;
                self.form.LnglatAmap =
                  result.regeocode.crosses[0].location.lng +
                  ',' +
                  result.regeocode.crosses[0].location.lat;
                self.form.Adcode = result.regeocode.addressComponent.adcode;
                self.address = result.regeocode.formattedAddress;
                // self.form.gisCoord.city =
                //   result.regeocode.addressComponent.city;
                self.form.gisCoord.province =
                  result.regeocode.addressComponent.province;
                self.form.gisCoord.latitude =
                  result.regeocode.crosses[0].location.lat;
                self.form.gisCoord.longitude =
                  result.regeocode.crosses[0].location.lng;
                self.form.gisCoord.county =
                  result.regeocode.addressComponent.district;
                self.form.gisCoord.countyCode =
                  result.regeocode.addressComponent.adcode;
                self.form.gisCoord.cityCode =
                  result.regeocode.addressComponent.adcode.substring(0, 4) +
                  '00';
                self.form.gisCoord.provinceCode =
                  result.regeocode.addressComponent.adcode.substring(0, 2) +
                  '0000';
                self.form.gisCoord.srid = 'GCJ-02';
                self.$nextTick();
              }
            }
          });
        },
      },
      location() {
        return;
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          maximumAge: 0, //定位结果缓存0毫秒，默认：0
          showButton: true, //显示定位按钮，默认：true
          buttonPosition: 'LB', //定位按钮停靠位置，默认：'LB'，左下角
          buttonOffset: new AMap.Pixel(10, 20),
          showMarker: true,
          panToLocation: true,
          zoomToAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 5000,
        });
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, 'complete', onComplete);
        AMap.event.addListener(geolocation, 'error', onError);
        // console.log('具体的定位信息')
        function onComplete(data) {
          // console.log(data)
          self.markers = [];
          self.form.address = data.formattedAddress;
          self.form.LnglatAmap = data.position.lng + ',' + data.position.lat;
          self.form.Adcode =
            data.addressComponent.adcode.substring(0, 4) + '00';
          self.lng = data.position.lng;
          self.lat = data.position.lat;
          self.center = [data.position.lng, data.position.lat];
          self.address = data.formattedAddress;
          self.markers.push([data.position.lng, data.position.lat]);
        }
        function onError(data) {
          // 失败 启用 ip定位
          AMap.plugin('AMap.CitySearch', function () {
            var citySearch = new AMap.CitySearch();
            citySearch.getLocalCity(function (status, result) {
              if (status === 'complete' && result.info === 'OK') {
                // 查询成功，result即为当前所在城市信息
                // console.log(result)
                self.center = [result.bounds.Gb.lng, result.bounds.Gb.lat];
                geocoder.getAddress(
                  [result.bounds.Gb.lng, result.bounds.Gb.lat],
                  function (status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                      if (result && result.regeocode) {
                        self.address = result.regeocode.formattedAddress;
                        self.$nextTick();
                      }
                    }
                  }
                );
              }
            });
          });
        }
      },
    };
  },
  methods: {
    oncitycascader(item){
      console.log(item);
      var labels=this.$refs.addressCascader.getCheckedNodes()[0].pathLabels ;
      this.form.provinceid=item[0];
      this.form.cityid=item[1];
      this.form.countyid=item[2];
      this.form.province=item;
      this.form.provincename=labels[0];
      this.form.cityname=labels[1];
      this.form.countyname=labels[2];
    },
    ongetentregtype(key) {
      $.each(this.entregtypedata, function (index, item) {
        if (key == item.data_title) {
          return item.id;
        }
      });
    },
    onbusinessendchange(value) {
      this.form.businessend = value;
    },
    onentregtypechange(item) {
      this.form.entregtypetxt = item;
      this.entregtypedata.forEach(t => {
        if(t.data_title==item){
          this.form.entregtypeid = item.id;
        }
      });
      if (this.form.entregtypetxt == '股份有限公司分公司') {
        this.isbranch = true;
      } else {
        this.isbranch = false;
      }
    },
    onentregcategorychange(item) {
      //this.form.entregcategoryid = item.id;
      this.form.entregcategorytxt = item;
      this.entregcategorydata.forEach(t => {
        if(t.data_title==item){
          this.form.entregcategoryid = item.id;
        }
      });
    },
    onentregstatuschange(item) {
      //this.form.entregstatusid = item.id;
      this.form.entregstatustxt = item;
      this.entregstatusdata.forEach(t => {
        if(t.data_title==item){
          this.form.entregstatusid = item.id;
        }
      });
    },
    onshowbusinessend(value) {
      this.isshowbusinessend = value;
      if (value) {
        this.form.businessend = '长期';
      } else {
        this.businessend = '';
      }
    },
    isShow() {
      this.show = !this.show;
    },
    hideDetail() {
      this.detailShow = false;
    },
    onsubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          companyauth(this.form).then((res) => {
            if (res.data.code === 1) {
              // this.$message({
              //   message: '恭喜你，提交成功',
              //   type: 'success',
              // });
              // setTimeout(function () {
              //   window.location.href = '/user/enterpriselist';
              // }, 300);
              this.$router.push({ path: '/user/enterpriseauth', query: { id: res.data.result } });
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log('提交失败!');
          return false;
        }
      });
    },
    oncompanygetentregcategory() {
      var that = this;
      companygetentregcategory().then((res) => {
        if (res.data.code === 1) {
          that.entregcategorydata = res.data.result;
        }
      });
    },
    oncompanygetentregtype() {
      var that = this;
      companygetentregtype().then((res) => {
        if (res.data.code === 1) {
          that.entregtypedata = res.data.result;
        }
      });
    },
    oncompanygetentregstatus() {
      var that = this;
      companygetentregstatus().then((res) => {
        if (res.data.code === 1) {
          that.entregstatusdata = res.data.result;
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogimgVisible = true;
    },
    busSuccess(response, file, fileList) {
      var that = this;
      this.fileList = fileList;
      if (response.code == 1) {
        this.form.businesslicenseimg = response.result.fileToken;
        this.loading = true;
        utilsbusinesslicenseocr({
          imgurl: response.result.url + '/raw',
        }).then((res) => {
          this.loading = false;
          if (res.data.code == 1) {
            var obj = res.data.result;
            $.each(obj, function (key, index) {
              if (key == '注册资本') {
                that.form.entregcapital = obj[key].replace('万元', '');
                if (that.form.entregcapital > 6) {
                  that.form.entregcapital = '';
                }
              } else if (key == '社会信用代码' && obj[key] != '无') {
                that.form.creditcode = obj[key];
              } else if (key == '单位名称' && obj[key] != '无') {
                that.form.name = obj[key];
              } else if (key == '法人' && obj[key] != '无') {
                that.form.legalperson = obj[key];
              } else if (key == '组成形式' && obj[key] != '无') {
                //$("#entregcapital").val(obj[key]);
              } else if (key == '成立日期' && obj[key] != '无') {
                that.form.establishdate = obj[key]
                  .replace('年', '-')
                  .replace('月', '-')
                  .replace('日', '');
                that.form.businessstart = obj[key]
                  .replace('年', '-')
                  .replace('月', '-')
                  .replace('日', '');
              } else if (key == '地址' && obj[key] != '无') {
                that.form.address = obj[key];
              } else if (key == '经营范围' && obj[key] != '无') {
                that.form.businessscope = obj[key];
              } else if (key == '类型' && obj[key] != '无') {
                if (obj[key].indexOf('有限责任公司') > -1) {
                  that.form.entregtypetxt = '有限责任公司';
                  that.form.entregtypeid = that.ongetentregtype(
                    that.form.entregtypetxt
                  );
                }
              } else if (
                key == '有效期' &&
                obj[key] != '无' &&
                obj[key] != '长期'
              ) {
                that.form.businessend = obj[key]
                  .replace('年', '-')
                  .replace('月', '-')
                  .replace('日', '');
              } else if (
                key == '有效期' &&
                obj[key] != '无' &&
                obj[key] == '长期'
              ) {
                that.form.businessend = '长期';
                that.isshowbusinessend = true;
              }
            });
          }
        });
      }
    },
    busError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    locatefal() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    addlocation() {
      this.dialogVisible = true;
    },
  },
  created() {
    this.oncompanygetentregcategory();
    this.oncompanygetentregtype();
    this.oncompanygetentregstatus();
  },
  mounted() {
    screenLeft;
    const self = this;
    setTimeout(function () {
      self.location();
    }, 800);
  },
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/
/* .accountset >>> form.elform .formaddress .el-input {
  width: 220px;
  margin-right: 10px;
} */
.accountset .info-cnt >>> .el-form-item__content button.showbusinessend {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  margin-left: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.chooseaddress {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.accountset >>> form.elform .small .el-form-item__label {
  line-height: 20px;
}
.accountset .account-nav li.cur i.detail {
  background-position: 0 -142px;
  background-image: url('../../assets/img/account-ico.png');
}
.amapstyle {
  height: 500px;
}
.accountset >>> .el-dialog__wrapper .amap-page-container .address {
  font-size: 14px;
  font-weight: 700;
  position: relative;
  top: -23px;
  width: 80%;
}
</style>
